*{
    margin: 0;
    padding: 0;
}

body{
    background-color: #ffede5;
}

td{
    text-align: center !important;
}