/* .center > tr > th,td{
    text-align: center !important;
} */

.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.loading img{
    width: 3%;
}

.empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.empty img{
    width: 10%;
}

.column{
    display: flex;
    flex-direction: column;
}

th{
    text-align: center !important;
}