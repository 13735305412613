ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 10px;
}

button{
    width: 100%;
    text-align: left;
}


.logo{
    width: 10%;
    border-radius: 10px;
}

svg{
    height: 20px;
}