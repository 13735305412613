.newUserBtn{
    background-color: green;
    color: white;
    position: absolute;
    bottom: 40px;
    width: 150px;
    right: 30px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}